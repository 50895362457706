import resume from '../assets/BoHubbard_Resume.pdf';
export default function Home() {
    return (
        <div className="relative isolate px-6 pt-14 lg:px-8 z-0" id={'home'}>
            <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                 aria-hidden="true">
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] z-0"
                    ></div>
            </div>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                </div>
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Bo Hubbard</h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">Chicago-born Developer, Graphic Designer & Programmer.</p>
                    <div className="mt-10 flex flex-col sm:flex-row gap-3 items-center justify-center gap-x-6">
                        <a href="#contact"
                           className="rounded-2xl bg-indigo-600 px-3.5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:shadow-md transition-all">Contact
                            me <span
                                aria-hidden="true"><i className='bx bx-right-arrow-alt align-middle'></i></span></a>
                        <a href={resume} target={'_blank'}
                           className="text-xl font-semibold text-gray-900 rounded-2xl bg-white px-3.5 py-2.5 hover:shadow-md transition-all">Download CV <span
                            aria-hidden="true"><i className='bx bx-download align-middle'></i></span></a>
                        <div className={"flex gap-2"}>
                            <a className={"bg-white rounded-full px-2 py-1 hover:shadow-md transition-all"} href={'https://www.linkedin.com/in/bo-hubbard-4988b2246/'}>
                                <i className='bx bxl-linkedin text-xl'></i>
                            </a>
                            <a className={"bg-white rounded-full px-2 py-1 hover:shadow-md transition-all"} href={'https://github.com/bubb4rd'}>
                                <i className='bx bxl-github text-xl'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true">
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    ></div>
            </div>
        </div>
)
}