import './App.css';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import {useRef} from "react";
import {useScroll, useTransform} from "framer-motion";
import ScrollReveal from "./components/ScrollReveal";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
function App() {

  return (
      <>
          <Navbar/>
          <Home/>`
          <ScrollReveal>
              <About/>
          </ScrollReveal>
          <ScrollReveal>
              <Skills/>
          </ScrollReveal>
          <Projects/>
          <ScrollReveal>
              <Contact/>
          </ScrollReveal>
      </>
  );
}

export default App;
