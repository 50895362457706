const skil = {
    skill: 'Google Firebase',
    img: './src/assets/firebase.png',
};
export default function Skill({skill}) {
    return (
        <div className={"flex gap-2 border-2 border-gray-300 w-fit justify-evenly p-4 bg-gray-100 text-black rounded-2xl items-center"}>
            <img src={skill.img}/>
            <h2 className={'font-bold align-middle h-fit'}>{skill.skill}</h2>
        </div>
    )
}