import React, {useEffect, useRef, useState} from "react";
import emailjs, { send } from "@emailjs/browser";
export default function Home() {
    const form = useRef();
    const sendEmail = (e) => {
        const button = document.getElementById('contact-submit');
            e.preventDefault();
            emailjs.sendForm(
                'service_o199izi',
                'template_a944037',
                form.current,
                'xpY7UemGR-1GYIahJ'
            ).then((result) => {
                console.log(result.status);
            }, (error) => {
                console.log(error)
            })

    }
    return (
        <div className="relative isolate px-6 pt-14 lg:px-8 z-0" id={'contact'}>
            <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                 aria-hidden="true">
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] z-0"
                ></div>
            </div>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get in touch</h2>
            </div>
            <form className="mx-auto mt-16 max-w-xl sm:mt-20" ref={form} onSubmit={sendEmail}>
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            First name
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="first-name"
                                name="first-name"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full px-3.5 py-2 text-black bg-transparent sm:text-sm sm:leading-6 border-b-2 border-black outline-0"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Last name
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="last-name"
                                name="last-name"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full border-0 px-3.5 py-2 text-black bg-transparent sm:text-sm sm:leading-6 border-b-2 border-black outline-0"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="block w-full border-0 px-3.5 py-2 text-black bg-transparent sm:text-sm sm:leading-6 border-b-2 border-black outline-0"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Message
                        </label>
                        <div className="mt-2.5">
                          <textarea
                              id="message"
                              name="message"
                              rows={4}
                              className="block w-full border-0 px-3.5 py-2 text-black bg-transparent sm:text-sm sm:leading-6 border-b-2 border-black outline-0"
                              defaultValue={''}
                          />
                        </div>
                    </div>

                </div>
                <div className="mt-10">
                    <button onClick={(e) => {
                        setTimeout(function() { window.location.reload(); }, 2000);
                        }
                    }
                        type="submit" id={'contact-submit'}
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all"
                    >
                        Let's talk
                    </button>
                </div>
            </form>
            <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true">
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                ></div>
            </div>
        </div>
    )
}