export default function Navbar() {

    const toggleMenu = () => {
        let menu = document.getElementById('mobile-menu');
        menu.classList.toggle('hidden');
    }
    return (
        <header className="fixed md:absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-end lg:justify-center p-6 lg:px-8" aria-label="Global">

                <div className="flex lg:hidden">
                    <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => {
                        let menu = document.getElementById('mobile-menu');
                        menu.classList.toggle('hidden');
                    }}>
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    <a href="#home" className="text-sm font-semibold leading-6 text-gray-900" >Home</a>
                    <a href="#about" className="text-sm font-semibold leading-6 text-gray-900">About</a>
                    <a href="#skills" className="text-sm font-semibold leading-6 text-gray-900">Skills</a>
                    <a href="#projects" className="text-sm font-semibold leading-6 text-gray-900">Projects</a>
                </div>
            </nav>
            <div className="hidden" role="dialog" aria-modal="true" id={'mobile-menu'}>
                <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"  id={'menu'}>
                    <div className="flex items-center justify-end">
                        <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={toggleMenu}>
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <a href="#home" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-center sm:text-left" onClick={toggleMenu}>Home</a>
                                <a href="#about" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-center sm:text-left" onClick={toggleMenu}>About</a>
                                <a href="#skills" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-center sm:text-left" onClick={toggleMenu}>Skills</a>
                                <a href="#projects" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-center sm:text-left" onClick={toggleMenu}>Projects</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}