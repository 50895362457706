import Skill from "./Skill";
import c from '../assets/c++.png'
import f from '../assets/figma.png'
import fire from '../assets/firebase.png'
import js from '../assets/js.png'
import java from '../assets/jV.png'
import mongo from '../assets/mongo.png'
import node from '../assets/node.png'
import ps from '../assets/ps.png'
import python from '../assets/python.png'
import react from '../assets/react.png'
import tailwind from '../assets/tailwind.png'
import git from '../assets/git.png'

const skills = [
    {
        skill: 'C++',
        img: c,
    },
    {
        skill: 'Java',
        img: java,
    },
    {
        skill: 'Python',
        img: python,
    },
    {
        skill: 'Javascript',
        img: js,
    },
    {
        skill: 'TailwindCSS',
        img: tailwind,
    },
    {
        skill: 'React',
        img: react,
    },
    {
        skill: 'nodeJS',
        img: node,
    },
    {
        skill: 'Google Firebase',
        img: fire,
    },
    {
        skill: 'mongoDB',
        img: mongo,
    },
    {
        skill: 'Adobe Photoshop',
        img: ps,
    },
    {
        skill: 'Figma',
        img: f,
    },
    {
        skill: 'Git',
        img: git,
    },
]
export default function Skills() {
    return (
        <div className={' px-10 sm:px-32 lg:px-56 my-52'} id={'skills'}>
            <h1 className={'text-3xl text-center font-black'}>My Skills</h1>
            <div className={'flex flex-wrap gap-2 justify-center py-4'}>
                {
                    skills.map((skill, index) => {
                        return <Skill skill={skill} key={index}/>
                    })
                }
            </div>
        </div>
    )
}