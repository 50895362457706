import projects from '../projects.json'
import Project from "./Project";
export default function Projects() {

    return (
        <section id={'projects'} className={'lg:px-56 px-24 py-10 z-20'}>
            <h1 className={'text-3xl text-center font-black mb-4'}>Project Experience</h1>
            <div className={"flex flex-wrap gap-5 justify-center"}>
                {
                    projects.map((project, index) => {
                        return <Project project={project} key={index}/>
                    })
                }

            </div>
        </section>
    )
}