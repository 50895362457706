export default function About() {

    return (
            <div className={"text-center px-14 sm:px-32 lg:px-56 py-10"} id={'about'}>
                <h1 className={'text-3xl font-black mb-4'}>About me</h1>
                <div className={'flex flex-col gap-3'}>
                    <p>
                        I am a creative developer from the Chicagoland area in Illinois. My main passion is software
                        development but I have interests in a lot of different areas including: <span
                        className={"font-semibold"}>graphic design</span>, <span className={"font-semibold"}>web & fullstack development</span>,
                        and <span className={"font-semibold"}>cybersecurity</span>.
                        I began my journey of development from one of my favorite
                        hobbies: video games. I started writing code and developing games through ROBLOX and their
                        editors, which spurred my
                        fascination with programming and design. I took what I learned from ROBLOX and began learning
                        other
                        programming languages, such as <span className={"font-semibold"}>C++</span>, <span
                        className={"font-semibold"}>Java</span>, and <span className={"font-semibold"}>HTML/CSS</span>.
                        With this knowledge in hand, I was able to
                        create my own projects and ideas. From then on my interest in coding only continued to grow as a
                        fun
                        hobby, which eventually led me into seeking roles in the tech industry.
                    </p>
                    <p>
                        I am currently attending <span className={"font-semibold text-[#8C1D40]"}>Arizona State University</span> studying
                        my
                        Bachelor's degree for <span className={"font-semibold"}>Computer Science</span>. I enjoy
                        developing solutions
                        that make a difference and have impact.
                    </p>
                </div>
            </div>
    )
}